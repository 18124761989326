<template>
    <el-main>
        <div class="left">文档内容：</div>
        <div>
            <div v-for="item in list" :key="item.id" class="list_item">
                <div class="row">
                    <ReadyUploadSource @getSource="getSource($event,item)" :path="item.cover_picture" @removeThis="() => (item.cover_picture = '')"></ReadyUploadSource>
                    <span>建议图片尺寸960*720px，大小不超过1M</span>
                    <el-link :underline="false" v-if="form.id==item.id" @click="saveDocument"><i class="el-icon-document-checked"></i></el-link>
                    <el-link :underline="false" v-else @click="delDocument(item.id)"><i class="el-icon-circle-close"></i></el-link>
                </div>
                <div class="row">
                    <el-input disabled v-model="item.document"></el-input>
                    <label for="inputer" @click="form.id = item.id;form.cover_picture = item.cover_picture">上传</label>
                    <input type="file" ref="inputer" id="inputer" @input="getFile($event,item)"/>
                </div>
            </div>

            <div class="list_item" v-if="is_add">
                <div class="row">
                    <ReadyUploadSource @getSource="(val) => (addform.cover_picture = val.path)" :path="addform.cover_picture" @removeThis="() => (addform.cover_picture = '')"></ReadyUploadSource>
                    <span>建议图片尺寸960*720px，大小不超过1M</span>
                    <el-link :underline="false" @click="addDocument"><i class="el-icon-document-add"></i></el-link>
                </div>
                <div class="row">
                    <el-input disabled v-model="addform.document"></el-input>
                    <label for="inputeradd">上传</label>
                    <input type="file" ref="inputer" id="inputeradd" @input="getFileAdd"/>
                </div>
            </div>
             <el-link :underline="false" type="primary" v-else @click="is_add = true"><i class="el-icon-plus"></i> 添加文档</el-link>
        </div>
    </el-main>
</template>

<script>
import ReadyUploadSource from '@/components/readyUploadSource';
export default {
    components:{
        ReadyUploadSource
    },
    data() {
        return {
            list:[],
            form:{
                id:'',
                cover_picture:'',
                document:''
            },
            addform:{
                cover_picture:'',
                document:''
            },
            is_add:false
        };
    },
    created(){
        this.getDocumentList()
    },
    methods:{
        getDocumentList(){
            this.$axios.post(this.$api.PCOfficialWebsite.getDocumentList).then(res => {
                if (res.code == 0) {
                   this.list = res.result
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getFile($event,item){
            let files = $event.target.files;
            let formData = new FormData();
            formData.append('files', files[0]);
            this.$axios.uploadFile(this.$api.user.addFiles, formData)
            .then(res => {
                if (res.code == 0) {
                    item.document = res.result.path
                    this.form.document = res.result.path
                }else{
                    this.$message.error(res.msg);
                }
            }).catch(() => {});
        },
        getFileAdd($event){
            let files = $event.target.files;
            let formData = new FormData();
            formData.append('files', files[0]);
            this.$axios.uploadFile(this.$api.user.addFiles, formData)
            .then(res => {
                if (res.code == 0) {
                    this.addform.document = res.result.path
                }else{
                    this.$message.error(res.msg);
                }
            }).catch(() => {});
        },
        getSource(val,item){
            item.cover_picture = val.path
            this.form.id = item.id
            this.form.document = item.document
            this.form.cover_picture = val.path
        },
        saveDocument(){
            this.$axios.post(this.$api.PCOfficialWebsite.editDocument,this.form).then(res => {
                if (res.code == 0) {
                    this.$message.success('保存成功')
                    this.form = {
                        id:'',
                        cover_picture:'',
                        document:''
                    }
                   this.getDocumentList()
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        delDocument(id){
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.post(this.$api.PCOfficialWebsite.delDocument,{id}).then(res => {
                    if (res.code == 0) {
                        this.$message.success('删除成功')
                        this.getDocumentList()
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            }).catch(() => {});
        },
        addDocument(){
            this.$axios.post(this.$api.PCOfficialWebsite.addDocument,this.addform).then(res => {
                if (res.code == 0) {
                    this.$message.success('添加成功')
                    this.addform = {
                        cover_picture:'',
                        document:''
                    }
                    this.is_add = false
                    this.getDocumentList()
                } else {
                    this.$message.error(res.msg);
                }
            });
        }
    }
};
</script>

<style lang="less" scoped>
    .el-main {
        min-height: 100%;
        background-color: #fff;
        display: flex;
        font-size: 14px;
        padding: 60px;
        .left{
            line-height: 108px;
            margin-right: 10px;
        }
        .list_item{
            margin-bottom: 50px;
        }
        .row{
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            span{
                margin-left: 20px;
                margin-right: 240px;
            }
            i{
                font-size: 24px;
            }
            .el-link+.el-link{
                margin-left: 10px;
            }
            .el-input{
                width: 500px;
            }
            .import{
                position: relative;
            }
            label{
                width: 80px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                margin-left: -2px;
                border: 1px solid #1467ff;
                border-radius: 4px;
                box-sizing: border-box;
                position: relative;
                z-index: 10;
                color: #1467ff;
                cursor: pointer;
                background-color: #eef3ff;
            }
            input[type='file']{
                display: none;
            }
        }
    }
</style>
